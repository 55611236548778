<!--
=========================================================
* sketch.media - crm 2.0 - v1.1.2
=========================================================

* Product Page: https://sketch.media
* Copyright 2023 sketch.media GmbH (https://www.sketch.media)

Coded by sketch.media GmbH

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
  <div id="wrapper" @notice-added="consoleTrigger()" :class="this.$route.name" v-if="this.$store.state.configLoader.loadPercent === 0">

    <main class="main-content">


      <div class="appContainer">
        <div class="mainWrap">
      <router-view  />
          <Menubar v-if="this.$route.name !== 'Signin'" />
        </div>
      </div>
    </main>
  </div>
  <div id="preloader"  v-else>
    <div class="loader">
      <div class="loadbarwrap">
      <div class="loadbar" :style="'width:'+this.$store.state.configLoader.loadPercent+'%;'"></div></div>

      <div class="loader__figure" style="margin-bottom: 24px;">{{this.$store.state.configLoader.loadPercent}} %</div>
      <p class="loader__message">{{this.$store.state.configLoader.loadMessage}}</p>
    </div>
  </div>

</template>
<script>
import Menubar from "@/sublayouts/Menubar/Bar.vue";
import Navbar from "@/sublayouts/Navbars/Navbar.vue";
import {mapMutations, mapActions, mapGetters} from "vuex";
import Cookies from "js-cookie";


export default {
  name: "App",

  data () {
    return {
      deferredPrompt: null,
    }
  },


  components: {
    Menubar,
    Navbar,
  },
  methods: {
    ...mapMutations(["toggleConfigurator"]),
    ...mapActions(["getPlugins", "getEmployees", "checkToken"]),
    async dismiss() {
      Cookies.set("add-to-home-screen", null, { expires: 15 });
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    showNotification(title, text) {
      let VueInner = this;
      if (!('Notification' in window)) {
        console.log('This browser does not support notifications.');
      } else if (Notification.permission === 'granted') {
        this.createNotification(title, text);
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            this.createNotification(title, text);
          }
        });
      }
    },
    checkNotificationPermission() {
      if (!('Notification' in window)) {
        console.log('This browser does not support notifications.');
      } else if (Notification.permission !== 'granted') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');
          }
        });
      }
    },
    createNotification(title, text) {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.ready.then(registration => {
          registration.showNotification(title, {
            body: text,
            icon: '/img/icons/android-chrome-192x192.png', // Optional, path to your icon
            badge: '' // Optional, path to your badge
          });
        });
      } else {
        new Notification(title, {
          body: text,
          icon: '/img/icons/android-chrome-192x192.png' // Optional, path to your icon
        });
      }
    },
    initializePusher() {
      Pusher.logToConsole = false;
      let VueInner = this;
      this.pusher = new Pusher('d4e91a37c1c6462058ae', { cluster: 'eu' });
      this.channel = this.pusher.subscribe('VWGtzSU2pnW7CA6ZyTVbMTQmPcZQYFQsGmeeRyT7AkfzNF8jXTnsu9pDB4FuG9Yy');
      this.channel.bind('news-update', (data) => { // Trigger the notification

        let title = data.title;
        let text = data.message; // Adjust based on the actual data structure
        let isNew = data.isNew;
        if (isNew) {
          this.showNotification(title, text);
        }
        VueInner.$store.dispatch("getLatestNews");
      });
    }
  },
  computed: {
  },
  created() {
    this.checkNotificationPermission();
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      if (Cookies.get("add-to-home-screen") === undefined) {
        this.deferredPrompt = e;
        this.$confirm(
          "Du kannst diese App auf deinem Gerät installieren. Möchtest du das jetzt tun?",
          "Willkommen!",
          "info",
          {
            confirmButtonText: "Ja",
            cancelButtonText: "Nein",
            type: "info",
          }
        )
          .then(() => {
            this.install();
          })
          .catch(() => {
            this.dismiss();
          });
      }
    });
    window.addEventListener("appinstalled", () => {
      console.log("app is installed");
      this.deferredPrompt = null;
    });

    // check if iphone standalone
    if (window.navigator && window.navigator.standalone === true || window.matchMedia("(display-mode: standalone)").matches) {
      console.log("display-mode is standalone");
    }
    else {
      // if iphone trigger popup to install
      if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        if (Cookies.get("add-to-home-screen") === undefined) {
          this.$alert(
            "<p>Du kannst diese App mit Safari auf deinem Gerät installieren und zum Startbildschirm hinzufügen</p><ul><li>Tippe auf das Teilen-Symbol, das du mittig am unteren Bildschirmrand (Quadrat mit Pfeilnach oben) findest</li><li>Scrolle in den sich öffnenden Optionen nach unten und wähle \"Zum Home-Bildschirm\" aus.</li><li>Gib einen Namen für die App ein (falls gewünscht) und tippe auf \"Hinzufügen\" - und fertig!</li></ul>",
            "Willkommen!",
            "info",
            {
              confirmButtonText: "Ok",
              type: "info",
            }
          )
            .then(() => {
              this.dismiss();
            })
            .catch(() => {
              this.dismiss();
            });
        }
      }
    }


    this.$store.commit("createStorage");
  },
  mounted() {

    this.initializePusher();
  },
  beforeMount() {


  },
  emits: ["noticeAdded"]
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
@import "./assets/scss/styles.scss";
.Vue-Toastification__container {
  z-index: 9999999 !important;
}
</style>
