
<template>
  <div class="container newsBoxCarousel">
        <carousel>
          <slide v-for="(snew, key) in news" :key="key" itemsToShow="1" autoplay="9000" :wrapAround="true">
            <a v-if="snew.externalRoute" :href="snew.externalRoute"  target="_blank"><div class="card mb-3 w-100" >
              <img v-if="snew.images.image_intro" class="card-img-top" :src="snew.images.image_intro" :alt="snew.title">
              <div class="card-body">
                 <span class="date">{{ $filters.formatDate(snew.publish_up) }}</span>

                <p class="title" >{{snew.title }}</p>
                <span class="readmore">Weiterlesen</span>

              </div>

            </div></a>
          </slide>
          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </carousel>
      </div>

</template>
<script>
import axios from "axios";
import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'
export default {
  name: "Newsbox",
  data() {
    return {
      news : []
    };
  },
  components: {
    Carousel, Slide, Navigation, Pagination
  },
  methods: {
    getNews(){
      let apiurl = this.$store.state.apiUrl;
      axios.get(apiurl + "content/newsflash?limit=5")
      .then((response) => {
        this.news = response.data.result.data;
      })
      .catch((error) => {
        console.log(error);
      });

    }
  },
  created() {
    this.getNews();
  },
  mounted(){
    this.getNews();
  }
};
</script>

