
<template>
</template>
<script>
export default {
  mounted() {
    if (this.$route.path === '/logout') {
      this.logoutUser();
    }
  },
  methods: {
    logoutUser() {
      this.$store.commit('logout');
    },

  }
};
</script>

