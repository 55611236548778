<template>
  <nav
    class="navbarTop navbar"
    id="navbarBlur"
  >
    <div class="row w-100">

        <span
          @click.prevent="logout()"
          class=" btn btn-small btn-logout" :tooltip="$t('logout')" position="bottom"
        ><i class="fa-regular fa-arrow-right-from-bracket"></i></span>


    </div>




  </nav>
</template>
<script>
import {mapActions} from "vuex";

import breadcrumbs from "../Breadcrumbs.vue";
export default {
  name: "navbar",
  data() {
    return {
      showMenu: false
    };
  },
  components: {breadcrumbs},
  methods: {
    ...mapActions(["logout"]),
  },

};
</script>
