<template>
  <div class="appViewHeading">
    <div class="container">
      <h1>
        <template v-if="this.$route.name === 'Signin'">
          <img src="/img/bdfl_ball_white.svg" class="logoBall"/>
        </template>
        <template v-else>
          <router-link :to="'/dashboard'"><img src="/img/bdfl_ball_white.svg" class="logoBall"/></router-link>
        </template>
        {{ $t('headline.' + $route.name) }}
      </h1>
    </div>
  </div>
  <main class="main-content signup">
    <section>
      <div class="container">
        <div class="row">
          <div class="mx-auto col-xl-12 col-lg-12 col-md-12 d-flex flex-column mx-lg-0">
            <div class="logoContainer">
              <img src="/img/logo_bdfl.svg" alt="Logo" class="logo">
            </div>
            <div class="loginWrap" v-if="!reset">
              <div class="card-body">
                <div class="mb-3">
                  <div class="input-group">
                    <input type="email" class="form-control form-control-lg" @keypress.enter="login(credentials)"
                           v-model="credentials.username" placeholder="Mitgliedsnummer / Email"/>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="input-group">
                    <!-- add Show password button -->
                    <template v-if="passwordShow">
                      <input type="text" class="form-control form-control-lg" @keypress.enter="login(credentials)"
                             v-model="credentials.password" placeholder="Passwort" name="password"/>
                      <span class="passwordEye" @click="showPassword"><i class="fa-regular fa-eye-slash"></i></span>
                    </template>
                    <template v-else>
                      <input type="password" autocomplete="true" class="form-control form-control-lg"
                             @keypress.enter="login(credentials)" v-model="credentials.password" placeholder="Passwort"
                             name="password"/>
                      <span class="passwordEye" @click="showPassword"><i class="fa-regular fa-eye"></i></span>
                    </template>
                  </div>
                  <div class="text-right text-sm" style="text-align: right;">
                    <p style="font-size: 12px; cursor: pointer;" @click.prevent="resetPassword()">Passwort
                      vergessen?</p>
                  </div>
                </div>
                <div class="mb-3">
                </div>
                <!--                    <argon-switch id="rememberMe">Remember me</argon-switch>-->
                <div class="text-center">
                  <button
                    class="mt-4 btn btn-primary w-100"
                    @click="login(credentials)"
                  >Anmelden
                  </button>
                </div>
              </div>
            </div>
            <div class="card card-plain"  v-else>
              <div class="pb-0 card-header text-start">
                <h5 class="font-weight-bolder">Passwort zurücksetzen</h5>
                <p class="mb-1">Hinweis: Bitte mindestens 8 Zeichen, einen Großbuchstaben und ein Sonderzeichen verwenden</p>
              </div>
              <div class="card-body">
                <div class="mb-3">
                  <div class="input-group">
                    <template v-if="passwordShow">
                      <input type="text" class="form-control form-control-lg"
                             v-model="credentials.password" placeholder="Passwort" name="password"/>
                      <span class="passwordEye" @click="showPassword"><i class="fa-regular fa-eye-slash"></i></span>
                    </template>
                    <template v-else>
                      <input type="password" autocomplete="true" class="form-control form-control-lg"
                             v-model="credentials.password" placeholder="Passwort"
                             name="password"/>
                      <span class="passwordEye" @click="showPassword"><i class="fa-regular fa-eye"></i></span>
                    </template>
                  </div>
                </div>
                <div class="mb-1">
                  <div class="input-group">
                    <template v-if="passwordShow2">
                      <input type="text" class="form-control form-control-lg"
                             v-model="credentials.password2" placeholder="Passwort" name="password"/>
                      <span class="passwordEye" @click="showPassword2"><i class="fa-regular fa-eye-slash"></i></span>
                    </template>
                    <template v-else>
                      <input type="password" autocomplete="true" class="form-control form-control-lg"
                             v-model="credentials.password2" placeholder="Passwort"
                             name="password"/>
                      <span class="passwordEye" @click="showPassword2"><i class="fa-regular fa-eye"></i></span>
                    </template>


                  </div>
                </div>
                <div class="text-center">
                  <button
                    class="mt-4 btn btn-primary w-100"
                    @click="changePassword(credentials)"
                  >Passwort ändern
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import Navbar from "@/sublayouts/PageLayout/Navbar.vue";
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
const body = document.getElementsByTagName("body")[0];
import {mapActions} from "vuex";
import axios from "axios";

export default {
  name: "signin",
  data() {
    return {
      reset: false,
      credentials: {
        email: "",
        password: "",
        password2: ""
      },
      currentSlide: {
        title: "sketch.manager",
        subtitle: "Das CRM System für Ihre Website.",
        image: "/img/login_bild_1_small.jpg"
      },
      slides: [
        {
          title: "sketch.manager",
          subtitle: "Das CRM System für Ihre Website.",
          image: "/img/login_bild_1_small.jpg"
        },
        {
          title: "sketch.manager",
          subtitle: "Das CRM System für Ihre Website.",
          image: "/img/login_bild_2_small.jpg"
        },
        {
          title: "sketch.manager",
          subtitle: "Das CRM System für Ihre Website.",
          image: "/img/login_bild_3_small.jpg"
        },
      ],
      passwordShow: false,
      passwordShow2: false
    }
  },
  methods: {
    ...mapActions([
      'login',
      'logout'
    ]),
    changePassword() {
      if (this.credentials.password !== this.credentials.password2) {
        this.$store.dispatch("toastError", "Die Passwörter stimmen nicht überein!");
      } else {
        axios.put(this.$store.state.apiUrl + "login", {
          changePassword: this.$route.params.resetToken,
          password: this.credentials.password,
          password2: this.credentials.password2
        }).then(() => {
          this.$store.dispatch("toastSuccess", "Sie können sich jetzt einloggen.");
          localStorage.removeItem("resetPassword");
          this.reset = false;
          this.$router.push({name: "signin"});
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    resetPassword() {
      if (!this.credentials.username) {
        this.$store.dispatch("toastError", "Bitte geben Sie Ihre E-Mail Adresse ein.");
      } else {
        this.$confirm('Soll das Passwort wirklich zurückgesetzt werden?', 'Passwort vergessen?', 'question', {
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein',
          type: 'warning'
        }).then(() => {
          // get baseurl
          let href = window.location.href;
          // generate random string
          let randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
          let returnUrl = href + "/" + randomString;
          localStorage.setItem("resetPassword", randomString);
          axios.put(this.$store.state.apiUrl + "login", {
            resetPassword: this.credentials.username,
            returnUrl: returnUrl
          }).then(() => {
            this.$store.dispatch("toastSuccess", "Bitte prüfen Sie Ihr E-Mail-Postfach und befolgen Sie die nächsten Schritte!");
          }).catch((r) => {
            this.$store.dispatch("handleApiResponse", r.response);
          });
        }).catch(() => {
          this.$store.dispatch("toastInfo", "Passwort wurde nicht zurückgesetzt!");
        });
      }
    },
    changeSlide() {
      // change slide every 7 seconds
      let i = 0;
      setInterval(() => {
        if (i < this.slides.length - 1) {
          i++;
        } else {
          i = 0;
        }
        this.currentSlide = this.slides[i];
      }, 7000);
    },
    showPassword() {
      this.passwordShow = !this.passwordShow;
    },
    showPassword2(){
      this.passwordShow2 = !this.passwordShow2;
    }
  },
  components: {
    Navbar,
  },
  created() {
    if (this.$route.params && this.$route.params.resetId) {
      if (localStorage.getItem("resetPassword") === this.$route.params.resetId) {
        this.reset = true;
        this.$store.dispatch("toastSuccess", "Bitte geben Sie ein neues Passwort ein!");
      } else {
        this.$store.dispatch("toastError", "Der Link ist abgelaufen!");
        this.$router.push("/signin");
      }
    }
  },
};
</script>
<style>
#wrapper.Signin .appContainer, #wrapper.SigninReset .appContainer {
  padding: 0;
}

#slideBox {
  transition: .3s all ease 0s;
}
.passwordEye{
  z-index: 99;
  color: #000;

}
.passwordEye i:before{
  color: #000;
}
</style>
