import {createRouter, createWebHashHistory} from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Signin from "../views/Signin.vue";
import store from "../store/index"
import Signout from "@/views/Signout.vue";
const routes = [
  {
    path: "/",
    name: "DashboardHome",
    component: Dashboard,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    children: [
      {
        path: '/signin/:resetId/:resetToken',
        name: "SigninReset",
        component: Signin,
      }
    ]
  },
  {
    path: ("/logout"),
    name: "Logout",
    component: Signout,

  },
  {
    path: "/menu",
    name: "Menu",
    component: () => import("../views/Menu.vue"),
  },
  {
    path: "/fortbildungen",
    name: "Fortbildungen",
    component: () => import("../views/Fortbildungen.vue"),
  },
  {
    path: "/wissensnetz",
    name: "Wissensnetz",
    component: () => import("../views/Wissensnetz.vue"),
  },
  {
    path: "/forum",
    name: "Forum",
    component: () => import("../views/Forum.vue"),
    children: [
      {
        path: '/forum/:id',
        name: "ForumThread",
        component: () => import("../views/Forum.vue"),
      }
    ]
  },
  {
    path: "/rechtliches",
    name: "Rechtliches",
    component: () => import("../views/Rechtliches.vue"),
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () => import("../views/Impressum.vue"),
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: () => import("../views/Datenschutz.vue"),
  },
  {
    path: "/agb",
    name: "Agb",
    component: () => import("../views/Agb.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue"),
    children: [
      {
        path: '/news/:id',
        name: "Newsitem",
        component: () => import("../views/Newsitem.vue"),
      }
    ]
  },
  {
    path: "/myqr",
    name: "Myqr",
    component: () => import("../views/Myqr.vue"),
  },
  {
    path: "/portal",
    name: "Portal",
    component: () => import("../views/Portal.vue"),
  },
  {
    path: '/portal/dates',
    name: "Dates",
    component: () => import("../views/Portal/Dates.vue"),
    children: [
      {
        path: '/portal/dates/:id',
        name: "Date",
        component: () => import("../views/Portal/Date.vue"),
      }
    ]
  },
  {
    path: '/portal/documents',
    name: "Documents",
    component: () => import("../views/Portal/Documents.vue"),
  },
  {
    path: '/portal/devices',
    name: "Devices",
    component: () => import("../views/Portal/Devices.vue"),
  },
  {
    path: '/portal/profile',
    name: "Profile",
    component: () => import("../views/Portal/Profile.vue"),
  },
  {
    path: '/portal/addresses',
    name: "Addresses",
    component: () => import("../views/Portal/Addresses.vue"),
  },
  {
    path: '/portal/password',
    name: "Password",
    component: () => import("../views/Portal/Password.vue"),
  },
  {
    path: '/portal/sepa',
    name: "Sepa",
    component: () => import("../views/Portal/Sepa.vue"),
  },
  {
    path: '/portal/overview',
    name: "Overview",
    component: () => import("../views/Portal/Overview.vue"),
  },
  {
    path: '/portal/membershipcard',
    name: "Membershipcard",
    component: () => import("../views/Portal/Membershipcard.vue"),
  },
  {path: '/:catchAll(.*)', component: Dashboard}
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  store.commit("forceSWupdate");
  if (store.state.scope === undefined) {
    store.dispatch("checkToken").then(function (data) {
      if (store.state.scope !== 'customer' && store.state.scope !== 'joomla-superuser' && to.name !== 'Signin' && to.name !== 'SigninReset') {
        router.push({name: 'Signin'});
      } else {
        next();
      }
    });
  } else {
    if ((store.state.scope !== 'customer' && store.state.scope !== 'joomla-superuser') && to.name !== 'Signin' && to.name !== 'SigninReset') {
      router.push({name: 'Signin'});
    } else {
      next();
    }
  }
});
export default router;
