<template>
  <div class="appViewHeading innerBgFull"  >

    <div class="container"><h1>
      <template  v-if="this.$route.name === 'Signin'">
        <img src="/img/bdfl_ball_white.svg" class="logoBall" />
      </template>
      <template v-else>
        <router-link :to="'/dashboard'"><img src="/img/bdfl_ball_white.svg" class="logoBall" /></router-link>
      </template>{{$t('headline.'+$route.name)}}</h1></div>

  </div>
  <Newsline />
  <div class="dashWrap" style="clear: both;">

   <!-- upcomming next api get /dates/next -->
    <datesbox class="mb-4"/>
    <div class="row mb-2">
      <div class="col text-center">
        <div class="btn btn-primary mb-4" @click="$router.push({name: 'Fortbildungen'})" >Alle Fortbildungen</div>
      </div>
    </div>
    <h2><span>News</span></h2>
    <newsbox class="mb-4"/>
    <div class="row">
      <div class="col text-center">
        <div class="btn btn-primary mb-4" @click="$router.push({name: 'News'})" >Alle News</div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapActions} from "vuex";
import newsbox from "@/sublayouts/Newsbox.vue";
import datesbox from "@/sublayouts/Datesbox.vue";
import Newsline from "@/sublayouts/Newsline.vue";
export default {
  name: "Dashbaord",
  data() {
    return {};
  },
  components: {
    newsbox
    , datesbox, Newsline
  },
  methods: {},
  created() {
  }
};
</script>
<style>
</style>


