<template>
  <div class="bottomBarWrap">
    <div class="innerBar">
      <div class="row">
        <div class="col">
        <span @click="$router.push('/myqr')">
        <i class="fa-regular fa-qrcode" :class="($route.path === '/myqr') ? 'active' : ''"></i>
        </span>
        </div>
        <div class="col"> <span @click="$router.push('/portal/dates')">
        <i class="fa-solid fa-calendar" :class="($route.path === '/portal/dates') ? 'active' : ''"></i> </span>
        </div>
        <div class="col">
         <span @click="$router.push('/forum');"
               :class="(getNewEntryForum && Object.keys(getNewEntryForum).length > 0  && hasTasks ) ? 'hasNew':''">
        <i class="fa-solid fa-comments" :class="($route.path === '/forum') ? 'active' : ''"></i>
         </span>
        </div>
        <div class="col">
      <span @click="$router.push('/menu')">
        <i class="fa-regular fa-bars" :class="($route.path === '/menu') ? 'active' : ''"></i></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {h} from "vue";

export default {
  name: "Bar",
  data() {
    return {
      newEntriesForum: [],
      hasTasks: false
    };
  },
  components: {},
  computed: {
    ...mapGetters(["getUser", "getNewEntryForum", "getSubscribedTasks"]),
  },
  methods: {
    checkTasks() {
      const subscribedTasks = this.getSubscribedTasks;

      const newEntries = this.getNewEntryForum;

      this.hasTasks = false;
      let taskIds = Object.keys(newEntries);

      let loop;
      let checkId;
      let loop2;
      let checkIssue;
      for(loop in taskIds) {
        checkId = taskIds[loop];

        for(loop2 in subscribedTasks) {
          checkIssue = subscribedTasks[loop2];
          if (checkIssue.id === checkId) {
            this.hasTasks = true;
            break;
          }
        }

      }



    }
  },
  created() {
    if (this.$route.params && this.$route.params.id) {
      // check if route.id is in getNewEntryForum
      let VueInner = this;
      // let newEntry = this.getNewEntryForum.filter(function (entry) {
      //   return entry.id === VueInner.$route.params.id;
      // });
      // if (newEntry.length > 0) {
      //   this.$store.dispatch("setNewEntryForum", {});
      // }
    }
  },
  mounted() {
    this.checkTasks();
    Pusher.logToConsole = false;
    var pusher = new Pusher('d4e91a37c1c6462058ae', {cluster: 'eu'});
    let VueInner = this;
    var channel = pusher.subscribe('VWGtzSU2pnW7CA6ZyTVbMTQmPcZQYFQsGmeeRyT7AkfzNF8jXTnsu9pDB4FuG9Yy');
    channel.bind('updates', function (data) {
      VueInner.$store.dispatch("setNewEntryForum", data);
      VueInner.checkTasks();
    });
  },
};
</script>
