import {createApp} from "vue";
import App from "./App.vue";
import store from "./store";
import './registerServiceWorker'
import router from "./router";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import 'vue-universal-modal/dist/index.css';
import VueUniversalModal from 'vue-universal-modal';
import VueSimpleAlert from "vue3-simple-alert-next";
import ckeditor from '@ckeditor/ckeditor5-vue';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueLogger from 'vuejs3-logger';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Multiselect from 'vue-multiselect'
// register globally
import {createI18n} from 'vue-i18n'
import messages from './lang';

const i18n = createI18n({
  locale: "de",
  fallbackLocale: "de",
  messages
})
export const appInstance = createApp(App);
const isProduction = process.env.NODE_ENV === 'production';
const optionsLogger = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};
appInstance.component('multiselect', Multiselect);
appInstance.use(i18n);
appInstance.use(VueLogger, optionsLogger);
appInstance.use(VueSimpleAlert);
appInstance.use(store);
appInstance.use(ckeditor);
const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: 'bottom-right'
};
appInstance.use(Toast, options);
appInstance.use(router);
appInstance.use(VueUniversalModal, {
  teleportTarget: '#modals',
});
appInstance.use(PerfectScrollbar, {
  watchOptions: true,
  options: {
    suppressScrollX: true
  }
});
appInstance.component("VudeDatePicker", VueDatePicker);
import logo from "@/assets/img/logo.png";

appInstance.config.globalProperties.$filters = {
  employeeName(employees, id) {
    let employee = employees.filter(obj => {
      return obj.id === id
    });
    employee = employee[0];
    if (employee && employee.e_name) {
      return employee.e_name;
    } else {
      return "Unbekannt";
    }
  },
  renderLineBreaks(value) {
    if (value) {
      return value.replace(/\r\n/g, "<br>").replace(/\n/g, "<br>");
      ;
    }
  },
  employeeIcon(employees, id, type) {
    if (type === undefined) {
      type = 'id';
    }
    let employee = employees.filter(obj => {
      return obj[type] === id
    });
    employee = employee[0];
    if (employee && employee._image) {
      return employee._image;
    } else {
      return logo;
    }
  },
  priceEuro(value) {
    return Number(value).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €';
  },
  formatTimeShort(value) {
    let date = new Date(value);
    return date.toLocaleTimeString("de-DE", {hour: '2-digit', minute: '2-digit'}) + " Uhr";
  },
  formatTimeShortFromTime(value) {
    let time = value.split(":");
    return time[0] + ":" + time[1];
  },
  formatDate(value) {
    let date = new Date(value);
    const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
    if (date.toLocaleDateString("de-DE", options) !== 'Invalid Date') {
      return date.toLocaleDateString("de-DE", options);
    }
  },
  formatDateNoYear(value) {
    let date = new Date(value);
    const options = {month: '2-digit', day: '2-digit'};
    if (date.toLocaleDateString("de-DE", options) !== 'Invalid Date') {
      return date.toLocaleDateString("de-DE", options);
    }
  },
  formatTime(value) {
    if(value){
    let date = new Date(value);
    // Without seconds
    return date.toLocaleTimeString("de-DE", {hour: '2-digit', minute: '2-digit'});
    }
    //return date.toLocaleTimeString("de-DE")+" Uhr";
  },
  formatDateTime(value) {
    let date = new Date(value);
    const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
    // dont render time if it is 00:00:00
    if (date.getHours() === 0 && date.getMinutes() === 0) {
      return date.toLocaleDateString("de-DE", options);
    } else {
      return date.toLocaleDateString("de-DE", options) + " | " + date.toLocaleTimeString("de-DE");
    }
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  nl2br(value) {
    return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
};
window.$ = window.jQuery = require('jquery');
appInstance.mount("#app");

