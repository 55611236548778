<template>
  <nav class="breadcrumb">
    <ol
      class="breadcrumb"
    >
      <li
        class="text-sm breadcrumb-item active"
        aria-current="page"
      >
        <i class="fa-regular fa-angle-double-right"></i> {{ $t('breadcrumbs.'+$route.name ) }}
      </li>
    </ol>
  </nav>
</template>
<script>
export default {
  name: "breadcrumbs",

  data() {
    return {};
  },

};
</script>
