<template>

  <div class="newsLineWrap" ref="marqueeWrap" v-if="getNewsItem" @click="sendToNewsitem(getNewsItem.id)">
     <div class="marquee"  :style="{ animationDuration: duration + 's' }" ref="marquee">
       <p ref="textContent"><strong>{{getNewsItem.title}}</strong> | <template v-if="getNewsItem.prepared && getNewsItem.prepared.introStripped">{{getNewsItem.prepared.introStripped}}</template></p>
     </div>
    <i class="fa-solid fa-info-circle"></i>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
export default {
  name: "Newsline",
  data() {
    return {

      duration: 10
    };
  },
  computed: {
    ...mapGetters(["getApiUrl", "getNewsItem"])
  },
  methods: {
    sendToNewsitem(id) {
      this.$router.push({name: 'Newsitem', params: {id: id}});
    },
    getLatestNews() {
      this.$store.dispatch('getLatestNews');
    },
    updateDuration() {
      this.$nextTick(() => {
        const marquee = this.$refs.marquee;
        const wrap = this.$refs.marqueeWrap;
        const textContent = this.$refs.textContent;
        if (marquee && wrap && textContent) {
          const textWidth = textContent.offsetWidth;
          const wrapWidth = wrap.offsetWidth;
          this.duration = (textWidth / wrapWidth) * 10; // Adjust multiplier for readability
          if(this.duration < 10){
            this.duration = 10;
          }
        }
      });
    },

  },
  mounted() {
    this.getLatestNews();
    this.updateDuration();
    window.addEventListener('resize', this.updateDuration);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDuration);
  }

};
</script>

